var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-card',[_c('v-card-title',[_c('div',{staticClass:"d-flex justify-space-between flex-wrap"},[_c('v-btn',{staticClass:"ma-2",attrs:{"dark":"","color":"primary"},on:{"click":_vm.createUser}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t('admin.users.add'))+" ")],1),_c('div',[_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":""},on:{"click":_vm.importExcel}},[_vm._v(" "+_vm._s(_vm.$t('common.importExcel'))+" ")]),_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":""},on:{"click":_vm.exportTo}},[_vm._v(" "+_vm._s(_vm.$t('common.export'))+" ")])],1)],1)]),_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('admin.users.title'))+" "),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":!_vm.search ? 'mdi-magnify' : undefined,"clearable":"","label":_vm.$t('common.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.dialog)?_c('user-create',{attrs:{"dialog":_vm.dialog,"item":_vm.itemSelected},on:{"update:dialog":function($event){_vm.dialog = $event},"update:table":_vm.fetchUsers}}):_vm._e(),(_vm.dialogPermissions)?_c('permissions-dialog',{attrs:{"dialog":_vm.dialogPermissions,"user":_vm.itemSelected},on:{"update:dialog":function($event){_vm.dialogPermissions = $event}}}):_vm._e(),_c('v-data-table',{staticClass:"elevation-1",attrs:{"options":_vm.options,"headers":_vm.headers,"footer-props":{ showFirstLastPage: true,
                                 'items-per-page-options': [5, 10, 30, 50, -1] },"items":_vm.items,"server-items-length":_vm.pagination.totalItems,"sort-by":_vm.sortby,"multi-sort":true},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
                                 var value = ref.value;
return [_c('div',{domProps:{"innerHTML":_vm._f("highlight")(value,_vm.search)}})]}},{key:"item.attributes.username",fn:function(ref){
                                 var value = ref.value;
return [_c('div',{domProps:{"innerHTML":_vm._f("highlight")(value,_vm.search)}})]}},{key:"item.attributes.name",fn:function(ref){
                                 var value = ref.value;
return [_c('div',{domProps:{"innerHTML":_vm._f("highlight")(value,_vm.search)}})]}},{key:"item.attributes.lastname",fn:function(ref){
                                 var value = ref.value;
return [_c('div',{domProps:{"innerHTML":_vm._f("highlight")(value,_vm.search)}})]}},{key:"item.attributes.email",fn:function(ref){
                                 var value = ref.value;
return [_c('div',{domProps:{"innerHTML":_vm._f("highlight")(value,_vm.search)}})]}},{key:"item.attributes.created-at",fn:function(ref){
                                 var value = ref.value;
return [_c('div',{domProps:{"innerHTML":_vm.dateHelper.getDateTimeTableFormat(value)}})]}},{key:"item.attributes.updated-at",fn:function(ref){
                                 var value = ref.value;
return [_c('div',{domProps:{"innerHTML":_vm.dateHelper.getDateTimeTableFormat(value)}})]}},{key:"item.attributes.enable",fn:function(ref){
                                 var value = ref.value;
return [_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s((value === 1) ? 'mdi-check' : "mdi-close")+" ")])]}},{key:"item.relationships.roles.data",fn:function(ref){
                                 var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getRoles.filter( function (r) { return value.find( function (v) { return v.id == r.id; }); } ).map(function (r) { return r.attributes.display_name; }).join(','))+" ")]}},{key:"item.actions",fn:function(ref){
                                 var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                 var on = ref.on;
                                 var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.edit')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                 var on = ref.on;
                                 var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.resetPasswordItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-lock-reset ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('admin.users.passwordReset')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                 var on = ref.on;
                                 var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.delete')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                 var on = ref.on;
                                 var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.showDialogPermissions(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-lock-open ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.permissions')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                 var on = ref.on;
                                 var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.showDialogGroups(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-group ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.groups')))])])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.fetchUsers}},[_vm._v(" "+_vm._s(_vm.$t('admin.common.resetData'))+" ")])]},proxy:true}])})],1),_c('confirm-dialog',{ref:"confirm"}),_c('input',{ref:"file",attrs:{"type":"file","hidden":"","accept":".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"},on:{"change":_vm.seletedFileToImport}}),_c('groupsTreeDialogSelect',{attrs:{"dialog":_vm.dialogGroups,"items-selected":_vm.groupsSelected},on:{"update:itemsSelected":_vm.changeGroupsSelected,"update:dialog":function($event){_vm.dialogGroups = $event}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('admin.users.groupsDialogTitle'))+" ")]},proxy:true},(_vm.itemSelected && _vm.itemSelected.attributes)?{key:"subtitle",fn:function(){return [_vm._v(" "+_vm._s(_vm.itemSelected.attributes.username)+" "+_vm._s(' { ' + _vm.itemSelected.attributes.email + ' }')+" ")]},proxy:true}:null,{key:"cleanFilters",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('admin.users.groupsDialogCleanFilter'))+" ")]},proxy:true}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }